<template>
  <div class="p-grid crud-demo">
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Panel header="OpenSupport® Reportes">
          <BasicFormToolbar @new="openNew" @list="list" :actions="['new', 'list']" />
          <div class="grid formgrid p-fluid">
            <div class="col-12">
              <Fieldset :toggleable="true" legend="Parametros">
                <div class="p-fluid formgrid grid">
                  <FormCalendar wrapperClass="field col-4" label="Fecha Inicial" v-model="entity.initial_date" />
                  <FormCalendar wrapperClass="field col-4" label="Fecha Final" v-model="entity.final_date" />
                </div>
              </Fieldset>
            </div>
            <div class="col-12">
              <TabView>
                <TabPanel header="Resultados">
                  <Fieldset :toggleable="true" legend="Resultados">
                    <BasicDatatable :rows="entities" :headers="headers" @edited="edit" />
                  </Fieldset>
                </TabPanel>
                <TabPanel header="Estatus">
                  <Fieldset :toggleable="true" legend="Resultados">
                    <div class="grid">
                      <div class="col-4">
                        <Chart ref="chartStatus" type="pie" :data="chartData" :options="lightOptions" />
                      </div>
                      <div class="col-4">
                        <h4>Porcentaje Cerrado : {{ (chartData.datasets[0].data[0] / (chartData.datasets[0].data[0] +
                        chartData.datasets[0].data[1])).toLocaleString(undefined, {
                          style: 'percent',
                          minimumFractionDigits: 2
                        }) }}</h4>
                        <h4>Porcentaje Abierto : {{ (chartData.datasets[0].data[1] / (chartData.datasets[0].data[0] +
                        chartData.datasets[0].data[1])).toLocaleString(undefined, {
                          style: 'percent',
                          minimumFractionDigits: 2
                        }) }}</h4>
                      </div>
                    </div>
                  </Fieldset>
                </TabPanel>
                <TabPanel header="Asignados">
                  <Fieldset :toggleable="true" legend="Resultados">
                    <div class="grid">
                      <div class="col-10">
                        <Chart ref="asignadosChart" type="bar" :data="asignData" :options="assignOptions" />
                      </div>
                      <div class="col-2">
                        <div style="margin-top: 1.5rem;" v-for="owner in porcentajes_owner" :key="owner.owner">
                          {{ owner.owner }}
                          <p><span style="color: green;">{{ owner.percent_abierto.toLocaleString(undefined, {
                          style: 'percent',
                          minimumFractionDigits: 2
                        }) }}</span>
                          <span style="color: red; margin-left: .5rem;">{{ owner.percent_cerrados.toLocaleString(undefined, {
                          style: 'percent',
                          minimumFractionDigits: 2
                        }) }}</span></p>
                        </div>
                      </div>
                    </div>
                  </Fieldset>
                </TabPanel>
                <TabPanel header="Autores">
                  <Fieldset :toggleable="true" legend="Resultados">
                    <div class="grid">
                      <div class="col-10">
                        <Chart ref="authorChart" type="bar" :data="authorData" :options="assignOptions" />
                      </div>
                      <div class="col-2">
                        <div style="margin-top: 1.5rem;" v-for="author in porcentajes_author" :key="author.author">
                          {{ author.author }}
                          <p><span style="color: green;">{{ author.percent_abierto.toLocaleString(undefined, {
                          style: 'percent',
                          minimumFractionDigits: 2
                        }) }}</span>
                          <span style="color: red; margin-left: .5rem;">{{ author.percent_cerrados.toLocaleString(undefined, {
                          style: 'percent',
                          minimumFractionDigits: 2
                        }) }}</span></p>
                        </div>
                      </div>
                    </div>
                  </Fieldset>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import { HeaderGrid } from "../../../utilities/General";
export default {
  mixins: [Session],
  props: {
    modal: null,
    id: null
  },
  data() {
    return {
      entity: {
        initial_date: new Date(new Date().getFullYear(), new Date().getUTCMonth(), 1),
        final_date: new Date()
      },
      headers: [
        new HeaderGrid("#", "ticket_number"),
        new HeaderGrid("Titulo", "title"),
        new HeaderGrid("Departamento", "department_name"),
        new HeaderGrid("Autor", "author_name"),
        new HeaderGrid("Asignado a", "owner_name"),
        new HeaderGrid("Fecha", "date_format", { type: 'date-time' }),
        new HeaderGrid("Cerrado a", "last_closed_at_format", { type: 'date-time' }),
      ],
      entities: [],
      loading: false,
      asignData: {
        labels: [],
        datasets: [
          {
            label: 'Abiertos',
            backgroundColor: '#42A5F5',
            data: []
          },
          {
            label: 'Cerrados',
            backgroundColor: '#FFA726',
            data: []
          }
        ]
      },
      authorData: {
        labels: [],
        datasets: [
          {
            label: 'Abiertos',
            backgroundColor: '#42A5F5',
            data: []
          },
          {
            label: 'Cerrados',
            backgroundColor: '#FFA726',
            data: []
          }
        ]
      },
      assignOptions: {
        indexAxis: 'y',
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      },
      chartData: {
        labels: ['Abiertos', 'Cerrados'],
        datasets: [
          {
            data: [300, 50],
            backgroundColor: ["#42A5F5", "#66BB6A"],
            hoverBackgroundColor: ["#64B5F6", "#81C784"]
          }
        ]
      },
      lightOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        }
      },
      porcentajes_owner: {},
      porcentajes_author: {}
    };
  },
  components: { Loader, BasicFormToolbar, BasicDatatable, FormCalendar },
  async mounted() {
    await this.list();
  },
  methods: {
    async list() {
      this.loading = true;
      try {
        this.entities = (await axios.post("OpenSupports/SupportTicket/tickets", this.entity)).data;
        this.entities = this.entities.map(x => {
          x.owner_name = x.owner_name ?? 'N/A';
          return x;
        })
        let abiertos = this.entities.reduce((a, b) => {
          if (b.closed == 0) {
            a = a + 1;
          }
          return a;
        }, 0
        );
        let cerrados = this.entities.reduce((a, b) => {
          if (b.closed == 1) {
            a = a + 1;
          }
          return a;
        }, 0
        );
        this.chartData.datasets = [
          {
            data: [abiertos, cerrados],
            backgroundColor: ["#42A5F5", "#66BB6A"],
            hoverBackgroundColor: ["#64B5F6", "#81C784"]
          }
        ];

        let staff_owners = [...new Set(this.entities.map(x => x.owner_name))]
        let authors = [...new Set(this.entities.map(x => x.author_name))]

        this.asignData.labels = staff_owners;
        this.authorData.labels = authors;


        let cerrados_by_owner = [];
        let abiertos_by_owner = [];
        let porcentajes_owner = [];

        let cerrados_by_author = [];
        let abiertos_by_author = [];
        let porcentajes_author = [];

        authors.forEach(author => {
          let cerrados = this.entities.reduce((acc, obj) => {
              if (obj.closed == 1 && obj.author_name == author) {
                acc = acc + 1;
              }
              return acc;
            }, 0);
          cerrados_by_author.push(cerrados);

          let abiertos = this.entities.reduce((acc, obj) => {
              if (obj.closed == 0 && obj.author_name == author) {
                acc = acc + 1;
              }
              return acc;
            }, 0);
          abiertos_by_author.push(abiertos);

          porcentajes_author.push({
            author: author,
            abiertos: abiertos,
            cerrados: cerrados,
            percent_abierto: abiertos / (abiertos + cerrados),
            percent_cerrados: cerrados / (abiertos + cerrados)
          }) 
        });

        staff_owners.forEach(staff_owner => {
          let cerrados = this.entities.reduce((acc, obj) => {
              if (obj.closed == 1 && obj.owner_name == staff_owner) {
                acc = acc + 1;
              }
              return acc;
            }, 0);
          cerrados_by_owner.push(cerrados);

          let abiertos = this.entities.reduce((acc, obj) => {
              if (obj.closed == 0 && obj.owner_name == staff_owner) {
                acc = acc + 1;
              }
              return acc;
            }, 0);

          abiertos_by_owner.push(abiertos);

          porcentajes_owner.push({
            owner: staff_owner,
            abiertos: abiertos,
            cerrados: cerrados,
            percent_abierto: abiertos / (abiertos + cerrados),
            percent_cerrados: cerrados / (abiertos + cerrados)
          }) 
        });

        this.asignData.datasets[0].data = abiertos_by_owner;
        this.asignData.datasets[1].data = cerrados_by_owner;

        this.authorData.datasets[0].data = abiertos_by_author;
        this.authorData.datasets[1].data = cerrados_by_author;

        this.porcentajes_owner = porcentajes_owner;
        this.porcentajes_author = porcentajes_author;

        this.$refs.chartStatus.refresh();
        this.$refs.asignadosChart.refresh();
        this.$refs.authorChart.refresh();

      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
